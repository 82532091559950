import React from 'react'
import { connectSearchBox } from 'react-instantsearch-dom';

const SearchBox = ({ currentRefinement, isSearchStalled, refine }) => (

  <input style={{
    margin: '0 auto',
    display: 'block',
    width: '35%',
    padding: '18px 20px',
    boxShadow: '0px 6px 15px 0px rgba(0, 0, 0, 0.07)',
    border: '1px solid rgba(0, 0, 0, 0.1)',
    outline: 0,
    borderRadius: 5,
    zIndex: 2,
    position: 'relative'
  }}
  type="search"
  placeholder="Search for anything..."
  value={currentRefinement}
  onChange={event => refine(event.currentTarget.value)}
  />

)

export default connectSearchBox(SearchBox)
