import React from 'react'
import { connectStateResults } from 'react-instantsearch-dom'

import SearchHits from './search-hits'

const SearchResults = connectStateResults(
  ({ searchState }) =>
    searchState && searchState.query
      ? <div>
          <div style={{
            background: '#000',
            opacity: 0.5,
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            zIndex: 1
          }}></div>
          <SearchHits />
        </div>
      : <div></div>
);

export default SearchResults
