import React from 'react'
import { InstantSearch  } from 'react-instantsearch-dom';

import SearchResults from './search-results'
import SearchBox from './search-box'


const Search = () => (
  <div
    style={{

    }}
  >
    <InstantSearch
      appId="5YR4WIY71S"
      apiKey="c7f3b17ad314093928aaf3a5a6a43923"
      indexName="ALLABOUT_SCHOOL"
    >


      <SearchBox />

      <SearchResults />
    </InstantSearch>
  </div>
)

export default Search
