import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'

import Search from './search'

const Header = ({ siteTitle }) => (
  <div
    style={{
      padding: '100px 0 50px 0'
    }}
  >
      <h1 style={{
        margin: '0 0 35px 0',
        textAlign: 'center',
        fontWeight: '300',
        fontSize: '3rem'
      }}>
        <Link
          to="/"
          style={{
            color: `black`,
            textDecoration: `none`,
          }}
        >
          allabout<span style={{
            background: 'linear-gradient(to right, #f00000, #dc281e)',
            color: '#fff'
          }}>.school</span>
        </Link>
      </h1>

      <Search />
  </div>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
