import React from 'react'
import { Link } from 'gatsby'
import { Highlight } from 'react-instantsearch-dom'

const SearchHit = ({ hit }) => (

  <Link to={hit.fields.slug} style={{
    textDecoration: 'none',
    color: 'black'
  }}>
    <div style={{
      background: '#fafafa',
      padding: 22,
      marginBottom: 7,
      borderRadius: 5
    }}>
      <h3 style={{
        fontSize: '1rem',
        marginBottom: 15
      }}>
        <Highlight attribute="frontmatter.title" hit={hit} tagName="mark" />

        <span style={{
            padding: '3px 8px',
            borderRadius: 6,
            textTransform: 'uppercase',
            fontSize: '0.7rem',
            color: '#fff',
            marginBottom: 10,
            marginLeft: 15,
            background: 'linear-gradient(to right, #396afc, #2948ff)'
        }}>{hit.frontmatter.category}</span>
      </h3>



      <br />

      <Highlight attribute="excerpt" hit={hit} tagName="mark" />
    </div>
  </Link>
)

export default SearchHit
