import React from 'react'
import { connectHits } from 'react-instantsearch-dom';

import SearchHit from './search-hit'

const SearchHits = ({ hits, topOffset }) => (

  <div style={{
    position: 'absolute',
    width: '35%',
    left: '50%',
    transform: 'translateX(-50%)',
    marginTop: 8,
    zIndex: 10
  }}>
    {hits.map(hit => (

      <SearchHit key={hit.objectID} hit={hit} />

    ))}
  </div>

)

export default connectHits(SearchHits)
